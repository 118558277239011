export function toSearchParams(
  obj: Record<string, any>,
  prefix?: string
): URLSearchParams {
  const searchParams = new URLSearchParams();

  function buildParams(data: Record<string, any>, base?: string): void {
    for (const key in data) {
      const value = data[key];
      const paramKey = base
        ? `${base}[${encodeURIComponent(key)}]`
        : encodeURIComponent(key);

      if (value && typeof value === "object") {
        // If the value is an array or an object, recursively build params
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            buildParams({ [index]: item }, `${paramKey}`);
          });
        } else {
          buildParams(value, paramKey);
        }
      } else {
        searchParams.append(paramKey, value);
      }
    }
  }

  buildParams(obj, prefix);
  return searchParams;
}

export function parseSearchParams(
  searchParams: URLSearchParams
): Record<string, any> {
  const result: Record<string, any> = {};

  for (const [key, value] of searchParams.entries()) {
    const keys = key.split(/\[|\]/).filter(Boolean); // Split keys by brackets and remove empty strings
    keys.reduce((acc, currentKey, index) => {
      if (index === keys.length - 1) {
        acc[currentKey] = value; // Set the value for the last key
      } else {
        acc[currentKey] =
          acc[currentKey] || (isNaN(Number(keys[index + 1])) ? {} : []);
      }
      return acc[currentKey];
    }, result);
  }

  return result;
}
