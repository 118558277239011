import {
  Combobox,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from "@headlessui/react";
import { useFetcher, useLoaderData } from "@remix-run/react";
import React, { ReactNode, useEffect, useMemo } from "react";

import { IRenderTemplate } from "./OptionTemplates";

import HighlightText from "~/components/atoms/HighlightText";
import { Icon } from "~/components/atoms/Icons";
import { InputProps } from "~/components/atoms/Input/type";
import LoadingDots from "~/components/atoms/LoadingDots";
import Typo from "~/components/atoms/Typo";
import { useDebounce } from "~/hooks/use-debounce-value";
import { cn } from "~/utilities/cn";
import { LIMIT } from "~/utilities/constants/common";
import { REQUEST_KEYS, REQUEST_NAME } from "~/utilities/constants/requestKey";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoVariant } from "~/utilities/enums/Typo";
import { toSearchParams } from "~/utilities/helpers/queryString";

export type AutocompleteOption = {
  label: string;
  value: string;
  [key: string]: any;
};

export type SearchBarProps = {
  inputProps: InputProps;
  containerClass?: string;
  iconSize?: {
    width: number;
    height: number;
  };
  iconClass?: string;
  // Option autocomplete
  enableAutocomplete?: boolean;
  asyncAutocomplete?: boolean;
  options?: AutocompleteOption[];
  // Option selected change event
  onSelectedChange?: (option: AutocompleteOption) => void;
  // Render option template
  renderOptionTemplate?: ({
    option,
    searchString,
  }: IRenderTemplate) => ReactNode;
  classNameOptions?: string;
  variant?: string;
};
function SearchBar({
  inputProps,
  iconSize,
  containerClass = "",
  iconClass,
  variant,

  // Option dropdown
  onSelectedChange,
  enableAutocomplete = false,
  asyncAutocomplete = true,
  options,
  renderOptionTemplate,
  classNameOptions,
}: SearchBarProps) {
  const [isShow, setIsShow] = React.useState<boolean>(false);
  const { onBlur, onFocus, defaultValue, ...restInputProps } = inputProps || {};
  const hideOptions = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(e);

    setIsShow(false);
  };
  const showOptions = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(e);

    setIsShow(true);
  };

  const searchString = (inputProps?.value as string) ?? defaultValue;

  const debounceValue = useDebounce(searchString, 500);

  const showAutoComplete =
    isShow && debounceValue?.length > 0 && enableAutocomplete;

  const fetcher = useFetcher<any>();
  const { optionsAutocomplete } = useLoaderData<any>();

  const fetchAutocompleteOptions = (page: number) => {
    // Dynamic fetching data from loader
    const query: any = {
      [REQUEST_KEYS.TRIGGER]: REQUEST_NAME.AUTOCOMPLETE_OPTIONS,
      autocompleteParams: {
        search: debounceValue || "",
        limit: LIMIT,
        page,
      },
    };

    const queryString = toSearchParams(query);

    if (queryString) {
      fetcher.load(`?${queryString?.toString()}`);
    }
  };

  useEffect(() => {
    if (debounceValue) {
      fetchAutocompleteOptions(1);
    }
  }, [debounceValue]);

  const dropdownOptions = useMemo(() => {
    if (!asyncAutocomplete) return options;

    return fetcher?.data?.optionsAutocomplete?.items;
  }, [options, optionsAutocomplete, fetcher?.data?.optionsAutocomplete?.items]);

  return (
    <div className="relative mt-1">
      <Combobox
        onChange={(option: AutocompleteOption) => {
          onSelectedChange?.(option);
        }}
      >
        {/*  */}
        <div
          className={cn(
            "flex w-full items-center gap-[10px] rounded-[28px] bg-searchBarBackground px-8 py-[18px] text-sub-title-16 text-color text-opacity-50 lg:px-4 lg:py-3",
            containerClass
          )}
        >
          <input
            type="text"
            {...restInputProps}
            className={cn(
              "grow border-none bg-transparent text-sub-title-16 text-color outline-none",
              inputProps?.className || ""
            )}
            onBlur={hideOptions}
            onFocus={showOptions}
          />
          <button type="submit" className={cn("hidden lg:block", iconClass)}>
            <figure className="cursor-pointer transition-all duration-500 hover:scale-110">
              <Icon
                TypeIcon={EIcon.SearchIcon}
                width={20}
                height={20}
                className="text-color"
                {...iconSize}
              />
            </figure>
          </button>
        </div>
        <Transition
          show={showAutoComplete}
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ComboboxOptions
            className={cn(
              "optionsBox scroll absolute z-[99999] mt-3 h-auto max-h-80 w-full overflow-auto  overflow-x-hidden  bg-white py-1 text-base shadow-dropdownSelect  focus:outline-none sm:text-sm",
              variant === "sticky" ? "rounded-xl" : "rounded-3xl lg:rounded-xl",
              classNameOptions
            )}
          >
            {fetcher.state === "loading" && (
              <div className="absolute left-0 top-0  flex h-full w-full items-center justify-center bg-black15">
                <LoadingDots numberOfLoading={1} />
              </div>
            )}
            {dropdownOptions && dropdownOptions.length > 0 ? (
              dropdownOptions.map((option: any) => (
                <ComboboxOption
                  key={option?.value}
                  value={option}
                  className="optionItem group flex cursor-pointer select-none items-center border-b px-8 py-4.5 hover:bg-cornflowerBlue data-[focus]:bg-cornflowerBlue lg:px-4 lg:py-3"
                >
                  {renderOptionTemplate ? (
                    renderOptionTemplate?.({
                      searchString: debounceValue,
                      option,
                    })
                  ) : (
                    <HighlightText
                      variant="text-body-title-16"
                      tag="span"
                      textHighlight={searchString as string}
                      color={ETypoColor.TEXT}
                    >
                      {option?.label}
                    </HighlightText>
                  )}
                </ComboboxOption>
              ))
            ) : (
              <p
                className={cn(
                  "flex items-center justify-center px-8 py-4.5 lg:px-4 lg:py-3",
                  variant === "sticky" ? "h-fit" : "h-[135px] lg:h-fit"
                )}
              >
                <Typo
                  tag="span"
                  color={ETypoColor.TEXT}
                  variant={ETypoVariant.HEADER_18}
                  className="opacity-75"
                >
                  {fetcher.state === "loading"
                    ? "Searching..."
                    : "No match anything, please try another keyword."}
                </Typo>
              </p>
            )}
          </ComboboxOptions>
        </Transition>
        {/* </div> */}
      </Combobox>
    </div>
  );
}

export default SearchBar;
