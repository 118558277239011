import Typo, { TypoProps } from "../Typo";

import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoVariant } from "~/utilities/enums/Typo";

export interface HighlightTextProps extends TypoProps {
  textHighlight?: string;
  children: string;
}

export default function HighlightText({
  textHighlight = "",
  children,
  ...typoProps
}: HighlightTextProps) {
  if (!textHighlight) {
    return <Typo {...typoProps}>{children}</Typo>;
  }

  // Split text by the highlight term (case-insensitive)
  const parts = children.split(new RegExp(`(${textHighlight})`, "gi"));

  return (
    <Typo {...typoProps}>
      {parts.map((part, index) =>
        part.toLowerCase() === textHighlight.toLowerCase() ? (
          <Typo
            key={index}
            color={ETypoColor.ERROR}
            tag="span"
            variant={ETypoVariant.HEADER_18}
          >
            {part}
          </Typo>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </Typo>
  );
}
